<template>
    <div class="animate__animated animate__fadeIn">
        <div :class="{'animate__animated animate__slideOutUp':selected_item !== null}" class="support-navigation">
            <div v-for="item in $variables.v.support_items"
                 :key="item.id">
                <v-card v-if="!item.hidden"
                        :color="item.enabled ? item.color : disabled_item_col"
                        :disabled="!item.enabled"
                        @click="open_subitem(item)"
                        class="disable-select mb-3">
                    <v-card-title>
                        <v-icon class="mr-3">{{item.icon}}</v-icon>
                        {{$t(item.text)}}
                    </v-card-title>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selected_item    : null,
                disabled_item_col: "blue-grey darken-3",
                support_items    : [
                    {
                        id   : 1,
                        route: this.$variables.v.navigation.rules
                    },
                    {
                        id   : 2,
                        route: this.$variables.v.navigation.faq
                    },
                    {
                        id   : 3,
                        route: this.$variables.v.navigation.donate
                    },
                    {
                        id   : 4,
                        route: this.$variables.v.navigation.mailsupport
                    },
                    {
                        id   : 5,
                        route: this.$variables.v.navigation.discord
                    },
                    {
                        id   : 6,
                        route: this.$variables.v.navigation.upcoming_features,
                    },
                ],

            }
        },
        methods: {
            open_subitem(item) {
                this.selected_item = this.support_items.find(x => x.id === item.id);
                setTimeout(async () => {
                    await this.$router.push(this.selected_item.route);
                }, 500);
            },
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-support')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>
